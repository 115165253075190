import api from "@/http";
import { ref } from "vue";
import useEventsBus from "@/utils/eventbus";
const { emitt } = useEventsBus();
const onProcess = ref(false);
const useAuth = import.meta.env.VITE_USE_AUTH === 'true'

const AuthChecker = {
  async pingBackend() {
    if(useAuth === false) return true;

    if (onProcess.value) return;
    onProcess.value = true;
    try {
      const response = await api.get(`auth/ping`);
      if (
        response &&
        response.data.message === "pong" &&
        response.data.status === "success"
      ) {
        return true;
      }
    } catch (error: any) {
      onProcess.value = false;
      // set store
      let errMessage = "";
      if (error.code === "ERR_NETWORK") {
        errMessage =
          "error.the application detects a problem with your internet network";
      } else {
        errMessage = error.response.data.message
          ? "error." + error.response.data.message
          : "undefined";
      }
      emitt("showToast", {
        type: "error",
        message: errMessage,
        duration: 3,
        position: "top-center",
      });
      return false;
    } finally {
      onProcess.value = false;
    }
  },

  // async authMiddleware(to: any, _from: any, next: any) {

  //     const paramLocale = to.params.locale;

  //     if (!AuthChecker.pingBackend()) {
  //       //jika bahasa tidak support ambil locale systemnya
  //       return next(AuthChecker.guessDefaultLocale());
  //     }
  //     await AuthChecker.switchLanguage(paramLocale)

  //     return next();
  //   },
};
export default AuthChecker;