import { createGlobalState, useStorage } from "@vueuse/core";

export const useAppRefState = createGlobalState(
  () =>
    useStorage("app-global-ref", {
      sideMenuIsOpen: false,
      currentTheme: "light",
      currentLang:"id",
    })

  // conts sideMenuIsOpen = () => ref(false)
  // const themeIsDark = ref(false);

  // function toggleSideMenu() {
  //   sideMenuIsOpen.value = !sideMenuIsOpen.value;
  // }

  // function closeSideMenu() {
  //   sideMenuIsOpen.value = false;
  // }

  // function toggleTheme() {
  //   themeIsDark.value = themeIsDark.value;
  // }

  // return { sideMenuIsOpen, themeIsDark, toggleSideMenu, closeSideMenu, toggleTheme };
);