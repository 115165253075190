import instance from "@/http";
import { /*arrayBufferToString,*/ getCookie } from "@/utils/commonfunctions";

// const errCodelist = [400, 403, 404, 405, 408, 429, 500];
const setupInterceptor = (store: any) => {
  /* eslint no-param-reassign: ['error', { 'props': true, 'ignorePropertyModificationsFor': ['config'] }] */
  const { raiseError } = store();

  instance.interceptors.request.use(
    (config: any) => {
      config.headers["App-Initial"] = "SSO";
      config.headers["X-Csrf-Token"] = getCookie("csrf_");
      config.timeout = 15000; // Wait for 15 seconds before timing out
      return config;
    },
    (error: any) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    (res: any) => res,
    async (err: any) => {
      // const originalConfig = err.config;
      // const unAuthStatus = [401];
      if (err && err.code === "ERR_NETWORK") {
        return raiseError({
          type: "error",
          code: 504,
          message: "error.cannot contact the server",
        });
      }
      // } else if (err.response && parseInt(err.response.status) === 429) {
      //   return raiseError({
      //     type: "error",
      //     code: 429,
      //     message: "error.too many request",
      //   });
      // } else if (err.response && parseInt(err.response.status) === 502) {
      //   return raiseError({
      //     type: "error",
      //     code: 502,
      //     message: "error.gateway error",
      //   });
      // } else if (err.code === 'ECONNABORTED' && err.message.includes('timeout')) {
      // }
      return Promise.reject(err);
    }
  );
};

export default setupInterceptor;