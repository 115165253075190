<script setup lang="ts">
import { computed, reactive, ref } from "vue";
import type { PropType } from "vue";
import type { ToastProps } from "@/constants/dataModel";

// let tShow: any;
// let tHide: any;

const props = defineProps({
  content: Object as PropType<ToastProps>,
});

const tShow = ref();
const tHide = ref();
const state = reactive({
  fadeClass: "fadeIn",
  typeClass: "warning",
  toastIsShow: false,
  toastNumber: 0,
});
const resolvePromise = ref();
const toastProps = computed(() => props.content);

//computed
const positionClass = computed(() => {
  let result = 'justify-center items-center'
  if (!props.content) return result;
  switch (props.content.position) {
    case 'top-left':
      result = 'justify-start items-center pl-5'
      break;
    case 'top-center':
      result = 'justify-center items-center'
      break;
    case 'top-right':
      result = 'justify-end items-center pr-5'
      break;
    default:
      break;
  }
  return result;
});

const typeClass = computed(() => {
  let result =
    "bg-amber-600 dark:bg-amber-800 dark:border-amber-700 border-amber-500";
  if (!props.content) return result;

  switch (props.content.type) {
    case "error":
      result = "bg-red-600 dark:bg-red-800 dark:border-red-700 border-red-500";
      break;
    case "warning":
      result =
        "bg-amber-600 dark:bg-amber-800 dark:border-amber-700 border-amber-500";
      break;
    case "info":
    case "success":
      result =
        "bg-teal-600 border-teal-500 dark:bg-teal-800 dark:border-teal-700";
      break;
    default:
      result =
        "bg-amber-600 dark:bg-amber-800 dark:border-amber-700 border-amber-500";
      break;
  }
  return result;
});

const show = () => {
  if (tShow.value) {
    state.toastNumber++;
    clearTimeout(tShow.value);
  }
  state.toastIsShow = true;
  state.fadeClass = "fadeIn";
  tShow.value = setTimeout(
    () => {
      closeToast();
    },
    toastProps?.value?.duration ? toastProps?.value?.duration * 1000 : 3000
  );
};

const closeToast = () => {
  if (tShow.value) clearTimeout(tShow.value);
  state.fadeClass = "fadeOut";
  tHide.value = setTimeout(() => {
    return new Promise((resolve) => {
      resolvePromise.value = resolve;
      state.toastIsShow = false;
      clearMe();
    });
  }, 500);
};

const clearMe = () => {
  if (tShow.value) clearTimeout(tShow.value);
  if (tHide.value) clearTimeout(tHide.value);
};

defineExpose({ show });
</script>
<template>
  <Teleport to="body">
    <div
      class="h-auto flex top-0 z-0 w-screen"
      :class="[positionClass]"
      v-if="state.toastIsShow"
      :key="state.toastNumber"
    >
      <div
        :class="[typeClass, state.fadeClass, positionClass]"
        class="animated w-11/12 md:max-w-md mx-auto overflow-hidden top-4 absolute z-50 px-3 py-4 p-4 text-sm rounded-lg text-white flex flex-row justify-center items-center space-x-3 border shadow-lg"
        role="alert"
      >
        <span class="animated infinite ringing">
          <mdicon name="bell-ring" class="h-2 w-2" />
        </span>
        <span class="font-medium leading-4">
          {{
            $t(
              toastProps?.message ? toastProps.message : "error.undefined error"
            )
          }}</span
        >
        <button @click="closeToast()" class="p-1 hover:bg-red-500 rounded-full">
          <mdicon
            height="16"
            width="16"
            name="close"
            class="text-white rounded-full"
          />
        </button>
      </div>
    </div>
  </Teleport>
</template>
