import { createApp } from "vue";
import i18n from "./constants/i18n";
import router from "./router";
import {VueReCaptcha} from "vue-recaptcha-v3"
import { useAxiosState } from "@/stores/axios";
import setupInterceptor from "@/http/interceptor";
import iconList from "@/constants/mdi";
import mdiVue from "mdi-vue/v3";
import { plugin as VueTippy } from 'vue-tippy'

import App from "./App.vue";
import MainApp from "@/components/layouts/MainApp.vue";

import "./css/index.scss";
const app = createApp(App);

const captchaSiteKey = import.meta.env.VITE_CAPTCHA_SITE_KEY;
setupInterceptor(useAxiosState);

app.use(router);
app.use(i18n);
app.use(mdiVue, { icons: iconList });


app.use(VueTippy, {
  directive: 'tippy',
  component: 'tippy',
  componentSingleton: 'tippy-singleton',
  defaultProps: {
      allowHTML: true,
      animation: 'perspective',
      placement: 'bottom',
      theme: 'dark',
      touch: 'hold',
  },
});


app.use(VueReCaptcha , { siteKey: captchaSiteKey, loaderOptions: {} } )

app.component("MainLayout", MainApp);
app.directive("focus", {
  mounted(el) {
    el.focus();
  },
});

app.mount("#app");
