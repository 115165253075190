import { createGlobalState } from "@vueuse/core";
import useEventsBus from "@/utils/eventbus";

export const useAxiosState = createGlobalState(() => {
  const { emitt } = useEventsBus();

  function raiseError(payload:any) {

    emitt("showMessageBox", {
      message: payload.message,
      title: "error."+payload.code,
      mode: "error",
      okButtonTitle: "button.ok",
      mustTranslate: true,
    });
  }

  return { raiseError };
});
