import { createRouter, createWebHistory } from "vue-router";
import { useAppRefState } from "@/stores/appref";
import Tr from "@/constants/i18n/translation";

// import Dashboard from "@/pages/user/Dashboard.vue";
const appRefState = useAppRefState();
// const isAdmin = true; //sampe dummy
// const isAuth = false;
const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL),
  routes: [
    {
      path: "/:locale?",
      name: "root",
      beforeEnter: Tr.routeMiddleware,
      children: [
        {
          path: "",
          name: "rootdir",
          component: () => import("@/pages/MainForm.vue"),
          meta: { layout: "main", mustAdmin: false, transition: "fade" },
        },   
      ],
    },
    {
      /* Not found page route */
      path: "/:locale?/:pathMatch(.*)*",
      component: () => import("@/pages/Error404.vue"),
      meta: { layout: "admin", mustAdmin: false, transition: "fade" },
    },
  ],
});

router.afterEach(() => (appRefState.value.sideMenuIsOpen = false));

router.onError((error) => {
  if (error.message.includes("Failed to fetch dynamically imported module")) {
    console.log("Gagal memuat komponen");
  }
});

export default router;