<script setup lang="ts">
import { computed, ref, reactive, watch, onMounted } from "vue";
import useEventsBus from "@/utils/eventbus";
import ConfirmBox from "@/components/reusables/ConfirmBox.vue";
import OfflineScreen from "@/components/reusables/OfflineScreen.vue";
import ToastAlert from "./components/reusables/ToastAlert.vue";
import type { ToastProps, MessageBoxProps } from "@/constants/dataModel";

import { /*RouterLink, */ RouterView, useRouter } from "vue-router";

//ref and computed
const toastProps = ref<ToastProps>();
const msgBoxProps = ref<MessageBoxProps>();
const toast = ref<InstanceType<typeof ToastAlert> | null>(null);
const confirm = ref<InstanceType<typeof ConfirmBox> | null>(null);
const { bus } = useEventsBus();
const { currentRoute } = useRouter();
const defaultLayout = import.meta.env.VITE_DEFAULT_THEME;

const data = reactive({
  messageBoxContent: msgBoxProps,
  toastContent: toastProps,
});

const layout = computed(
  () => `${currentRoute.value.meta.layout || defaultLayout}-layout`
);

watch(
  () => bus.value.get("showMessageBox"),
  (val) => {
    if (!val) return;
    showMessageBox(val);
  }
);
watch(
  () => bus.value.get("showToast"),
  (val) => {
    if (!val) return;
    showToast(val);
  }
);

onMounted(() => {
  console.info("app loaded");
});

const showToast = async (content: any) => {
  if (!content && !content[0]) return;
  data.toastContent = content[0];
  toast.value?.show();
};

//methods
const showMessageBox = async (content: any) => {
  if (!content && !content[0]) return;
  data.messageBoxContent = content[0]; //arr[0] must have object values

  await confirm.value?.show();
};
</script>
<template>
  <video autoplay muted loop id="videoBg">
    <source src="@/assets/pexel.mp4" type="video/mp4" />
  </video>
  <ConfirmBox ref="confirm" :content="data.messageBoxContent"></ConfirmBox>
  <ToastAlert ref="toast" :content="data.toastContent"></ToastAlert>
  <OfflineScreen ref="offline"></OfflineScreen>
  <component :is="layout">
    <router-view v-slot="{ Component }">
      <transition
        :name="String($route.meta.transition) || 'fade'"
        mode="out-in"
        :duration="{ enter: 500, leave: 500 }"
      >
        <component :is="Component" :key="$route.name"></component>
      </transition>
    </router-view>
  </component>
</template>
<style lang="css">
#videoBg {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  filter: brightness(30%);
  max-width: none;
  transform: translateX(calc((100% - 100vw) / 2));
}
</style>
