<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "Offlinelock",
  //   props: {
  //     content: Object as PropType<OfflineProps>,
  //   },
  setup() {
    // const resolvePromise = ref();
    const lockTheScreen = ref(false);

    const toggle = (val: boolean) => {
      lockTheScreen.value = val;
    };

    return {
      lockTheScreen,
      toggle,
    };
  },
});
</script>
<template>
  <Teleport to="body">
    <div
      v-if="lockTheScreen"
      id="offlineScreenLock"
      tabindex="-1"
      aria-hidden="true"
      class="fixed flex justify-center items-center top-0 left-0 right-0 z-50 w-screen p-4 overflow-hidden md:inset-0 h-screen bg-gray-900 bg-opacity-80"
    >
      <div
        class="h-full w-full p-8 relative mx-auto flex justify-center items-center bg-white dark:bg-gray-700 max-w-sm max-h-10 rounded-lg shadow-lg"
      >
        <div
          class="flex justify-center items-center space-x-2 text-gray-600 dark:text-gray-300"
        >
          <span class="animated infinite ringing">
            <mdicon
              class="text-red-600"
              name="network-off"
              height="38"
              width="38"
            />
          </span>
          <p class="leading-5 text-lg">
            {{ $t("error.your connection is lost please fix it") }}
          </p>
        </div>
      </div>
    </div>
  </Teleport>
</template>