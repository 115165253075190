import {
  mdiClose,
  mdiBellRing,
  mdiHelpCircle,
  mdiAlertCircle,
  mdiCloseCircle,
  mdiCheckCircle,
  mdiClipboardCheckOutline,
} from "@mdi/js";

const iconList = {
  mdiClose,
  mdiBellRing,
  mdiHelpCircle,
  mdiAlertCircle,
  mdiCloseCircle,
  mdiCheckCircle,
  mdiClipboardCheckOutline,
};

export default iconList;
