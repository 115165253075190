import { createI18n } from "vue-i18n";
import numberFormats from "./rules/number"
import datetimeFormats from "./rules/datetime"

import id from "./locales/id.json";

const i18n = createI18n({
  warnHtmlInMessage: false,
  locale: import.meta.env.VITE_DEFAULT_LOCALE,
  fallbackLocale: import.meta.env.VITE_FALLBACK_LOCALE,
  globalInjection: true,
  legacy: false,
  messages: { id },
  runtimeOnly: false,
  numberFormats,
  datetimeFormats
});

export default i18n;