import i18n from "./index";
import { nextTick } from "vue";
import { useAppRefState } from "@/stores/appref";
import RMiddleware from '@/router/middleware'

const appRefState = useAppRefState();

const Trans = {
  get defaultLocale() {
    return import.meta.env.VITE_DEFAULT_LOCALE;
  },

  get supportedLocales() {
    return import.meta.env.VITE_SUPPORTED_LOCALES.split(",");
  },

  get currentLocale() {
    return i18n.global.locale.value;
  },

  set currentLocale(newLocale: string) {
    i18n.global.locale.value = newLocale;
  },

  async switchLanguage(newLocale: string) {
    await Trans.loadLocaleMessages(newLocale);
    Trans.currentLocale = newLocale;
    let el = document.querySelector("html");
    if (el) el.setAttribute("lang", newLocale);
    appRefState.value.currentLang = newLocale;
  },

  async loadLocaleMessages(locale: string) {
    
    if (!i18n.global.availableLocales.includes(locale)) {
      const messages = await import(`@/constants/i18n/locales/${locale}.json`);
      i18n.global.setLocaleMessage(locale, messages.default);
    }

    return nextTick();
  },

  isLocaleSupported(locale: string | null) {
    if (!locale) return false;
    return Trans.supportedLocales.includes(locale);
  },

  getUserLocale() {
    const locale =
      window.navigator.language ||
      window.navigator.language ||
      Trans.defaultLocale;
    return {
      locale: locale,
      localeNoRegion: locale.split("-")[0],
    };
  },

  getPersistedLocale() {
    const persistedLocale = appRefState.value.currentLang
      ? appRefState.value.currentLang
      : "id";
    if (Trans.isLocaleSupported(persistedLocale)) {
      return persistedLocale;
    } else {
      return null;
    }
  },

  guessDefaultLocale() {
    const userPersistedLocale = Trans.getPersistedLocale();
    if (userPersistedLocale) {
      return userPersistedLocale;
    }

    const userPreferredLocale = Trans.getUserLocale();

    if (Trans.isLocaleSupported(userPreferredLocale.locale)) {
      return userPreferredLocale.locale;
    }

    if (Trans.isLocaleSupported(userPreferredLocale.localeNoRegion)) {
      return userPreferredLocale.localeNoRegion;
    }

    return Trans.defaultLocale;
  },

  async routeMiddleware(to: any, _from: any, next: any) {
    const paramLocale = to.params.locale;

    if (!Trans.isLocaleSupported(paramLocale)) {
      //jika bahasa tidak support ambil locale systemnya
      return next(Trans.guessDefaultLocale());
    }
    await Trans.switchLanguage(paramLocale)
    await RMiddleware.pingBackend()
    if (to.meta?.redirectTo) return next(Trans.i18nRoute({ name: to.meta.redirectTo }))
    return next();
  },

  i18nRoute(to: any) {
    return {
      ...to,
      params: {
        locale: Trans.currentLocale,
        ...to.params,
      },
    };
  },
};

export default Trans;