export default {
   en: {
     currencyFormat: {
       style: "currency",
       currency: "USD"
     }
   },
   id: {
     currencyFormat: {
       style: "currency",
       currency: "IDR"
     }
   }
 }