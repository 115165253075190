<script setup lang="ts">
import { ref, computed, defineAsyncComponent } from "vue";
import type { PropType } from "vue";
import type { MessageBoxProps } from "@/constants/dataModel";

const ConfirmContainer = defineAsyncComponent(
  () => import("./ConfirmContainer.vue")
);

const props = defineProps({
  content: Object as PropType<MessageBoxProps>,
});

const baseModal = ref<InstanceType<typeof ConfirmContainer>|null>(null);
const modeConfirm = [
  "confirmation",
  "confirm",
  "confirmpassword",
  "confirmtext",
];
const modeInput = ["confirmpassword", "confirmtext"];
const isConfirmMode = computed(() =>
  modeConfirm.includes(props?.content?.mode ? props?.content?.mode : "confirm")
);
const isConfirmInput = computed(() =>
  modeInput.includes(props?.content?.mode ? props?.content?.mode : "confirm")
);
const inputMode = computed(() =>
  props?.content?.mode === "confirmpassword" ? "password" : "text"
);
const msgMode = computed(() =>
  props?.content?.mode ? props.content.mode : "success"
);
const resolvePromise = ref();
const mustTranslate = computed(() =>
  props?.content?.mustTranslate ? props.content.mustTranslate : false
);

//methods
const show = () => {
  baseModal.value?.open();
  return new Promise((resolve) => {
    resolvePromise.value = resolve;
  });
};

const parseIcon = () => {
  if (isConfirmMode.value) return "help-circle";
  let iconName = "alert-circle";
  switch (msgMode.value) {
    case "warning":
      iconName = "alert-circle";
      break;
    case "error":
      iconName = "close-circle";
      break;
    case "info":
    case "success":
      iconName = "check-circle";
      break;
    default:
      iconName = "alert-circle";
      break;
  }
  return iconName;
};

const parseIconColor = () => {
  if (isConfirmMode.value) return "text-amber-500";
  let iconColor = "text-teal-500";
  switch (msgMode.value) {
    case "warning":
      iconColor = "text-amber-500";
      break;
    case "error":
      iconColor = "text-red-500";
      break;
    case "info":
    case "success":
      iconColor = "text-teal-500";
      break;
    default:
      break;
  }
  return iconColor;
};

const confirm = () => {
  baseModal.value?.close();
  resolvePromise.value(true);
};

const cancel = () => {
  baseModal.value?.close();
  resolvePromise.value(false);
};

defineExpose({ show });
</script>
<template>
  <ConfirmContainer ref="baseModal">
    <div class="p-3 bg-gray-100 dark:bg-gray-700">
      <div class="flex flex-col justify-center items-center">
        <mdicon
          :name="parseIcon()"
          height="80"
          width="80"
          class="icon-animated tada"
          :class="[parseIconColor()]"
        />
      </div>
      <div class="flex justify-center px-2">
        <p class="font-semibold text-xl uppercase">
          {{
            content?.title
              ? mustTranslate
                ? $t(content.title)
                : content.title
              : "title"
          }}
        </p>
      </div>
      <div class="mb-4 px-2">
        <div
          v-if="mustTranslate"
          class="mt-2"
          v-html="
            content?.message ? $t(content.message) : '<span>message</span>'
          "
        ></div>
        <div
          v-else
          class="mt-2"
          v-html="content?.message ? content.message : '<span>message</span>'"
        ></div>
      </div>
      <div class="flex" v-if="isConfirmInput">
        <input
          :type="inputMode"
          maxlength="50"
          size="50"
          v-focus
          class="font-mono bg-white dark:bg-gray-800 border-gray-400 dark:border-gray-500 w-full rounded-md focus:border-teal-300 focus:ring-transparent"
        />
      </div>
    </div>
    <div class="flex items-stretch justify-evenly overflow-hidden">
      <button
        class="w-1/2 h-12 bg-gray-200 dark:bg-gray-600 hover:bg-gray-300 dark:hover:bg-gray-500 active:bg-gray-300 dark:active:bg-gray-500 border-r border-t border-zinc-300 dark:border-zinc-800"
        @click="confirm"
        v-if="isConfirmMode"
      >
        {{
          content?.okButtonTitle ? $t(content.okButtonTitle) : $t("button.ok")
        }}
      </button>
      <button
        class="w-1/2 h-12 bg-gray-200 dark:bg-gray-600 hover:bg-gray-300 dark:hover:bg-gray-500 active:bg-gray-300 dark:active:bg-gray-500 border-t border-zinc-300 dark:border-zinc-800"
        @click="cancel"
        v-if="isConfirmMode"
      >
        {{
          content?.cancelButtonTitle
            ? $t(content.cancelButtonTitle)
            : $t("button.cancel")
        }}
      </button>
      <button
        class="w-full h-12 bg-gray-200 dark:bg-gray-600 hover:bg-gray-300 dark:hover:bg-gray-500 active:bg-gray-300 dark:active:bg-gray-500 border-t border-zinc-300 dark:border-zinc-800"
        @click="cancel"
        v-else
      >
        {{
          content?.okButtonTitle ? $t(content.okButtonTitle) : $t("button.ok")
        }}
      </button>
    </div>
  </ConfirmContainer>
</template>
