/* eslint func-names: ["error", "never", { "generators": "as-needed" }] */
import dayjs from "dayjs";
import "dayjs/locale/id";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
dayjs.locale("id");
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

dayjs.updateLocale("id", {
  updateLocale: {
    future: "%s lagi",
    past: "%s lalu",
    s: "sesaat lalu",
    m: "semenit lalu",
    mm: "%d menit",
    h: "sejam",
    hh: "%d jam",
    d: "sehari",
    dd: "%d hari",
    M: "sebulan",
    MM: "%d bulan",
    y: "setahun",
    yy: "%d tahun",
  },
});

export const timeAgo = (val: string) => {
  if (!val) return "";
  const cleanDate = dayjs(val.trim(), "MM-DD-YYYY");
  return dayjs(cleanDate).fromNow();
};

export const simpleTimeAgo = (val: string) => {
  if (!val) return "";
  const cleanDate = dayjs(val.trim(), "MM-DD-YYYY");
  return dayjs(cleanDate).fromNow(true);
};

export const indoDate = function (val: string) {
  if (!val) return "";
  return dayjs(val).format("DD MMMM YYYY HH:mm") + " WIB";
};

export const randomNumberBetween = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1) + min);

export const strCut = (str: string, limit: number) => {
  if (!str || !limit) return;
  if (str.length > limit) {
    return str.substring(0, limit - 1).trim() + "...";
  }
  return str;
};

export const strCutStripped = (str: string, limit: number) => {
  if (!str || !limit) return;
  const regex = /(<([^>]+)>)/gi;
  const stripped = str.replace(regex, "");
  return strCut(stripped, limit);
};

export const fileSize = (size: number) => {
  let result = null;
  if (!size) return result;

  if (size < 1024) {
    result = `${size.toString()} B`;
  }
  if (size > 1024) {
    result = `${(size / 1024).toFixed(2)} KB`;
  }
  if (size > 1024 * 1024) {
    result = `${(size / 1024 / 1024).toFixed(2)} MB`;
  }
  if (size > 1024 * 1024 * 1024) {
    result = `${(size / 1024 / 1024 / 1024).toFixed(2)} GB`;
  }
  if (size > 1024 * 1024 * 1024 * 1024) {
    result = `${(size / 1024 / 1024 / 1024 / 1024).toFixed(2)} TB`;
  }

  return result;
};

export const arrayBufferToString = (buffer: Uint8Array) => {
  const arr = new Uint8Array(<ArrayBuffer>buffer);
  const str = String.fromCharCode.apply(null, [...new Uint8Array(arr)]);
  if (/[\u0080-\uffff]/.test(str)) {
    throw new Error("this string seems to contain (still encoded) multibytes");
  }
  return str;
};
export const apply_blob = (element: any, image: any) => {
  new Blob([image]).text().then((value) => (element.value = value));
};

export const getFileExtension = (filename: string) => filename.split(".").pop();
export const periodData = (tipe: string, data: string) => {
  if (tipe === "Tahun") {
    return data.split(" ").pop();
  }
  return data;
};

export const removeFromArray = (original: any, remove: any) => {
  if (!original) {
    return [];
  }
  return original.filter((value: any) => !remove.includes(value));
};

export const addToArray = (original: any, newData: any) => {
  if (!newData) return;
  return original.concat(newData);
};

export const compareArray = (arr1: any, arr2: any) => {
  if (arr1.length != arr2.length) {
    return false;
  } else {
    const sortArr1 = arr1.sort();
    const sortArr2 = arr2.sort();
    let result = false;
    // comparing each element of array
    for (let i = 0; i < sortArr1.length; i++) {
      if (sortArr1[i] != sortArr2[i]) {
        return false;
      } else {
        result = true;
      }
    }
    return result;
  }
};

export const getCookie = (cookiename: string) => {
  const cookiestring = document.cookie;
  const cookiearray = cookiestring.split(";");
  for (let i = 0; i < cookiearray.length; ++i) {
    if (cookiearray[i].trim().match("^" + cookiename + "=")) {
      return cookiearray[i].replace(`${cookiename}=`, "").trim();
    }
  }
  return null;
};

export const screenType = () => {
  const wPixel = window.innerWidth;
  let result = "lg";

  if (wPixel >= 320) result = "sm";
  if (wPixel >= 720) result = "md";
  if (wPixel >= 1150) result = "lg";
  if (wPixel >= 1200) result = "xl";
  if (wPixel >= 1900) result = "2xl";
  return result;
};